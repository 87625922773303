import styles from "./ForthScreen.module.css"
import FAQItem from "../components/questions/Questions";

const ForthScreen = () => {
  return <>
   
      <h2 className={styles.title}>זמן לענות על השאלות שלכן</h2>
      
      <div className={styles.section}>
        <h3 className={styles.subTitle}>מיקרובליידינג</h3>
        <FAQItem 
          question="האם טיפול מיקרובליידינג הוא כמו קעקוע?" 
          answer="טיפול המיקרובליידינג הוא ממש לא כמו קעקוע, גם מבחינת הפיגמנטים וגם מבחינת החדירה. בקעקוע אנחנו משתמשים בפיגמנטים שמתאימים לקעקוע ואינם יורדים מהעור ולעיתים אפילו משנים את צבעם , לעומת זאת המיקרובליידינג הפיגמנטים הם מינרלים ומתנדפים מהעור עם הזמן בצורה הדרגתית ואיתם משנים את הצבעם. וגם מבחינת החדירה- בקעקוע אנחנו חודרים לשכבות עמוקות הרבה יותר מבטיפול המיקרובליידינג שבו אנחנו חודרים רק לשכבה העליונה של העור"
        />
        <FAQItem 
          question="האם התוצאה יוצאת טבעית?" 
          answer="שיטת המיקרובליידינג היא השיטה הכי חדשנית וטבעית בעולם האיפור קבוע כיום , אני מציירת לך בטיפול שיערות שמשתלבות באופן מושלם עם השיערה הטבעית של הגבה שלך ומתאימה אותה גם מבחינת עובי השיערה וגם מבחינת הפיגמנט ככה שהתוצאה יוצאת סופר טבעית ומדויקת"
        />
        <FAQItem 
          question="למי מתאים טיפול המיקרובליידינג?" 
          answer="טיפול המיקרובליידינג מתאים לכל מי שמעוניינת לעשות שינוי בגבות שלה אבל בכל זאת להישאר עם המראה הטבעי , בין אם זה להוסיף שיערות, לעשות אפקט של הרמת העין, לעבות ולמלא את הגבות אבל בצורה העדינה והטבעית ביותר."
        />
      </div>

      <div className={styles.section}>
        <h3 className={styles.subTitle}>טיפולי פנים</h3>
        <FAQItem 
          question="האם אפשר להגיע לתוצאות משמעותיות החל מהטיפול הראשון?" 
          answer="כן בהחלט, החל מהטיפול פנים הראשון אצלי בקליניקה אני מאבחנת לך את מצב העור בצורה מדויקת וכבר מתחילה לעבוד עליו בהתאם בשילוב של טכנולוגיות מתקדמות ולגמרי תרגישי בהבדל החל מטיפול בודד אצלי ."
        />
        <FAQItem 
          question="האם טיפול הלייזר אצלי בקליניקה כואב?" 
          answer="אצלי בקליניקה אני עובדת עם מכונת לייזר להסרת שיער ללא כאבים כלל."
        />
      </div>
  
</>
};

export default ForthScreen;